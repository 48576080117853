.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: "GoldmanBold";
  src: local("GoldmanBold"),
   url("./Fonts/Poppins-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "Goldman";
    src: local("Goldman"),
     url("./Fonts/Poppins-ExtraLight.ttf") format("truetype");
    }

  .why, .prag, .yemm{
    font-family: "Goldman";
  }
 
.para{
  font-family: 'Goldman';
}

  .div, .parag, .paraf, .paragf, .praf, .paraexp, .cspara{
    font-family: 'GoldmanBold'
  }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.row{
margin: unset !important;
padding: unset !important;
 }

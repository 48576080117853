
.box-inside{    
    z-index: 20;
    box-shadow: 0 2px 1px 0 #ccc;
    -webkit-box-shaddow: 0 0 1px 0 #ccc;
    -moz-box-shadow:0 0 1px 0 #ccc;
    border-radius: 10px;
    margin-top: 150px;
    max-width: 350px;
}

.losg{
    border: none !important;
    box-shadow: none !important;
}

.col-2{
    background-color: black;
    height: 760px;
}
.col1div{
    padding: 65px;
}
.shadow12{
    border-right: none;
    border-top: none;
    border-left: none;
    border-radius: 0px;
}
.logmndiv1{
 display: none !important;
}

@media(min-width: 767px) and (max-width: 1100px){
.logonsimg{
    width:385px !important;
}
.nsim{
    margin-top:150px !important;
}
.col1div{
    padding: 40px !important;
}
}
@media(min-width: 280px) and (max-width: 767px){
.col-2{
    display: none !important;
}
.logmndiv{
    display: none !important;
    }
.logmndiv1{
    display: flex !important;
}
.bxinsdiv{
    display: flex !important;
    justify-content: center !important;
}
.box-inside{
    width: 100% !important;
    margin-top: 100px !important;
}
.logindiv{
    height: 600px !important;
}
.col1div{
    padding: 20px !important;
}
}
.conts{
    padding: 65px;
    background-color: rgb(0, 0, 0);
    height: 755px;
}
.conts1{
    padding: 65px;
    background-color: rgb(0, 0, 0);
    height: 755px;
  }
.css-typing44{
    display: none;
}
.cshead, .cusjo{
   font-size: 49px;
   color: #fff;
}
.cspara{
   font: size 22px;
   margin-top: 15px;
   color: white;
}
.grp4d{
    position: absolute;
    right: -64px;
    top:  -360px;
}
.grading{
  position: absolute;
}
.contsm, .contsm1{
    padding: 65px;
}
.contsm1{
  text-align: center;
}
.cusjo{
  margin-top: 100px;
}
.yemm{
    font-size: 22px;
    color: white;
    margin-top: 30px;
}
.hrln{
  height: 3px;
  color: white;
}
.ymbt{
  width: 100%;
  height: 50px;
  font-size: 22px;
  color: black;
  margin-top: 45px;
}

@media(min-width: 767px) and (max-width: 1200px){
    .grading{
      right: 0px !important;
    }
    .cspara{
      font-size: 18px !important;
    }
    }
@media(min-width: 767px) and (max-width: 945px){
    .conts{
        height: 500px !important;
      }
    .css-typing4{
      display: none !important;
    }
    .css-typing44{
      font-size: 35px !important;
      margin-top: 80px !important;
      display: block !important
    }
    .grading{
        width: 250px !important;
        right: 0px !important;
      }
      .cusjo, .cshead{
        font-size: 35px !important;
      }
      .cspara{
        font-size: 16px !important;
      }
      .yemdiv{
        margin-top: 0 !important;
      }
     }
     
     @media(min-width: 500px) and (max-width: 767px){
        .conts{
            height: 600px !important;
          }
          .conts1{
            height:unset !important;
          }
        .css-typing4{
            display: none !important;
          }
          .css-typing44{
            font-size: 35px !important;
            margin-top: 80px !important;
            display: block !important
          }  
        .grading{
           width: 300px !important;         
           position: unset !important;
        }
        .grp4im{
            width: 450px !important;
        }
        .grp4d{
            top: -270px !important;
        }
        .grading{
            width: 250px !important;
            right: 0px !important;
          }
          .yemdiv{
           padding: unset !important;
           margin-top: unset !important;
           display: 'flex';
           justify-content: 'start' !important;
            
          }
          .cusjo, .cshead{
            font-size: 35px !important;
          }
          .cspara{
            font-size: 20px !important;
          }
          .cusjo{
            margin-top: 0px !important;
          }
        }

    @media(min-width: 280px) and (max-width: 500px){ 
    .css-typing4{
      display: none !important;
    }
    .css-typing44{
      font-size: 30px !important;
      margin-top: 80px !important;
      display: block !important
    }
     .conts{
        padding:20px !important;
        height: 500px !important;
      } 
      .conts1{
        height:unset !important;
      }
      .grading{
        width: 250px !important;
        position: unset !important;
        margin-top: -150px !important;
      }  
      .gradh{
        display:flex;
        justify-content: center;
        margin-top: 140px !important;
      }
      .conts1{
        padding:20px !important;
      }
      .grp4im{
        display:none !important;
     }
     .yemdiv{
        display: flow-root !important;
        padding: 0px !important;
     }
      .cusjo, .cshead{
        font-size: 30px !important;
      }
      .cspara{
        font-size: 20px !important;
      }
      .cusjo{
        margin-top: 0px !important;
      }
     } 
    
   
.search{
  font-size: 40px ;
  font-weight: bold;
  text-align: center;
}

.apply{
  padding:65px;
  background-color:white;
}

.pros{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2); 
  margin-top: 30px;
  border-radius: 20px;
  padding: 20px;
}

.pros1{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2); 
  margin-top: 30px;
  border-radius: 20px;
  padding: 30px;
  background-color:black;
  
}

.pros:hover{
  transform: scale(1.02);
  box-shadow:  black;
}

.ul{
  list-style-type: circle;
}







.css-typing4 div {
    border-right: .10em solid rgb(16, 17, 16);
    white-space: nowrap;
    overflow: hidden;
  }
  .css-typing4 div:nth-child(1) {
    width: 11.3em;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .css-typing4 div:nth-child(2) {
    width: 14.5em;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid #7000ff;
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid #31036d;
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid #6604e6;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid rgb(2, 5, 3);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
 
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
  
  

  
 
   


/* .box-inside2{    
    z-index: 20;
    box-shadow: 0 2px 1px 0 #ccc;
    -webkit-box-shaddow: 0 0 1px 0 #ccc;
    -moz-box-shadow:0 0 1px 0 #ccc;
    border-radius: 10px;
    margin-top: 10px;
    max-width: 350px;
    position: absolute;
    left: 550px;
} */
.mbx{
    display: flex;
    justify-content:center;
}
.box-inside2{    
    z-index: 20;
    box-shadow: 0 6px 6px 0 #ccc;
    border-radius: 10px;
    margin-top: 125px;
    width: 350px;
    /* position: absolute;
    left: 600px; */
}

.col1div1{
    padding: 65px;   
}
.alrdy{
    margin-top: 20px;
    display: flex;
    justify-content:center;
    margin-bottom: 10px;
}
.rnd{
    position: absolute;
    bottom: -170px;
    left: 78px;
    display: flex;
    justify-content: end;
}
@media(min-width: 280px) and (max-width: 767px){
    .rndim{
        display: none !important;
    }
    .col1div1{
        padding: 20px !important;
    }
    .mndiv{
        height: 520px !important;
    }
    .box-inside2{
        margin-top:90px  !important;
    }
}
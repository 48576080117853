.divv{
    color: #8b8b8b;
}
.serv{
  padding:65px;
  background-color:black;
  height:  755px;
}
.wesl{
  position: absolute;
  top: -140px;
  position: absolute;
  
}
.about3{
  padding: 65px;
}
.lineo{
  position:absolute;
  top: -65px;
  left: -65px;
}
.crad {
  position: absolute;
  width: 200px;
  right: 0px;
  top: 210px;
  margin-right: 10px;
}
.img1{
  position: absolute;
  bottom: 200px;
}
.about4{
  padding:65px;
  background-color:black;
  padding-top: 10px;
}
.img2{
  position: absolute;

}
.img2sh{
    width: 600px;
    position: absolute;
    left: 0px;
    top: 250px;
}

.picdiv{
  padding:65px;
  height: 700px;
  background-color: #f3f3f3;
}
.picdiv1{
  padding:65px;
  height: 700px;
  background-color: #f3f3f3;
}
/* .recg{
  position: relative;
} */
.num{
  font-size: 45px;
  position: absolute;
  font-weight: bold;
  left: -96px;
}
.appnm{
  font-size: 45px;
  position: absolute;
  left: -67px;
  font-weight:550;
  top: 80px;
}
.depnm{
  font-size: 48px;
  position: absolute;
  left: -70px;
  font-weight:550;
  top: 135px;
}
.praf{
  position: absolute;
  top: 385px;
  left: -30px;
}
.wwdar{
display: flex;
position: absolute;
left: -90px;
bottom:290px
}
.num2{
  font-size: 45px;
  position: absolute;
  font-weight: bold;
  right: -68px;
  z-index: 1;
}
.sing{
  font-size: 45px;
  position: absolute;
  font-weight:550;
  top: 80px;
  right: -40px;
  z-index: 1;
}
.plat{
  font-size: 45px;
  position: absolute;
  font-weight:550;
  top: 135px;
  z-index: 1;
  right: -40px;
}
.paraexp{
  position: absolute;
  top: 385px;
  text-align: justify;
  left: 15px;
}
.wwdal{
  display: flex;
  position: absolute;
  right: -70px;
  bottom:290px
}
.devim{
    margin: top 100px;
}
.smdiv1{
  position: absolute;
  top: 275px;
  left: -40px;
}
@media(min-width: 767px) and (max-width: 1350px){
  .num{
    font-size: 40px!important;
    left: -35px;
  }
  .appnm{
    font-size: 40px!important;
    left: -13px!important;
  }
  .depnm{
    font-size: 40px!important;
    left: -14px!important;
  }
  .praf{
   font-size: 15px !important;
    top: 380px !important;
    left: 20px !important;
  }
  .smdiv1{
    left: 4px!important;
  }
  .wwdar{
  display: -webkit-inline-box !important;
  position: absolute;
  left: -90px!important;
  bottom:295px!important;
  }
  .clri{
    margin-left: 100px !important;
    width: 150px !important;
  }
  .wtwdo{
     margin-left: 50px !important;  
  }
  .wwdal{
    top: 550px !important;
    font-weight: bold !important;
  }
  .clile{
    width: 100px !important;
   margin-right: 250px !important;
   height: 50px !important;
  }
  .wtwedo{
    font-size: 18px !important;
  }
  .num2{
    font-size: 40px!important;
   
  }
  .sing{
    font-size: 38px!important;
  
  }
  .plat{
    font-size: 38px!important;
 
  }
  .paraexp{
    top: 330px !important;
  }
}

@media(min-width: 500px) and (max-width: 767px){
.picdiv{
  padding: 30px !important;
  padding-bottom: unset !important;
}
.colcol, .colcol2{
  position: unset !important;
}
.recg, .recb{
  display: none !important;
}
.num, .num2{
  font-size: 35px !important;
  position: unset !important;
  font-weight: bold;
}
.appnm, .sing{
  font-size: 35px !important;
  position: unset !important;
  font-weight:550;
}
.depnm, .plat{
  font-size: 35px !important;
  position: unset !important;
  font-weight:550;
}
.praf, .paraexp{
  position: unset !important;
  margin-top: 15px !important;
  font-size: 15px !important;
  text-align: unset !important;
}
.smdiv1{
  position: unset !important;
  margin-top: 10px !important;
}
.wwdar, .wwdal{
display: flex;
position: unset !important;
justify-content: space-between;
margin-top: 15px !important;
font-weight: bold !important;
}
.wtwdo, .wtwedo{
  font-size: 18px !important;
}
.colimg{
  margin-top: -70px !important;
  display: flex;
  justify-content: center;
}
.devimg, .devimg1{
  width: 80% !important;
  margin-top: 30px !important;
}
.devimg2{
  width: 80% !important;
}
.clile{
margin-right: unset !important;
}
}
@media(min-width: 280px) and (max-width: 500px){
  .picdiv, .picdiv1{
    padding: 20px !important;
    height: 600px !important;
  }
  .colcol, .colcol2{
    position: unset !important;
  }
  .recg, .recb{
    display: none !important;
  }
  .num, .num2{
    font-size: 25px !important;
    position: unset !important;
    font-weight: bold;
  }
  .appnm, .sing{
    font-size: 25px !important;
    position: unset !important;
    font-weight:550;
  }
  .depnm, .plat{
    font-size: 25px !important;
    position: unset !important;
    font-weight:550;
  }
  .praf, .paraexp{
    position: unset !important;
    margin-top: 15px !important;
    font-size: 12px !important;
  }
  .smdiv1{
    position: unset !important;
    margin-top: 10px !important;
  }
  .wwdar, .wwdal{
  position: unset !important;
  margin-top: 15px !important;
  font-weight: bold !important;
  }
  .wtwdo, .wtwedo{
    font-size: 16px !important;
  }
  .cliri, .clile{
    display: none!important;
  }
  .colimg{
    margin-top: -30px !important;
    display: flex;
    justify-content: center;
  }
  .devimg{
    width: 100% !important;
  }
  .devimg1, .devimg2{
    width: 80% !important;
  }
  }

@media(min-width: 962px) and (max-width: 1322px){
  .webu{
    font-size:30px !important;
  }
  .crad{
    display: none !important;
  }
  .paraf{
    font-size:16px !important;
  }
  .img1sh{
   width:400px !important;
  }
  .col{
    margin-top:50px  !important
  }
  .img2sh{
    width: 450px !important;
    top: 120px !important;
  }
  .webi{
    font-size:30px !important;
    margin-top:150px !important
  }
}
@media(min-width: 767px) and (max-width: 962px){
  .webu .webi{
    font-size:28px !important;
    margin-top: 100px !important;
  }
  .crad{
    display: none !important;
  }
  .paraf{
    font-size:16px !important;
  }
  .img1sh{
    display: none !important;
  }
  .lineo{
    width: 350px !important;
  }
  .webi{
    font-size:28px !important;
    margin-top: 20px !important;
  }
  .img2{  
    margin-top: 100px !important;
  }
  .img2sh{
    width: 350px !important;
    top: 150px !important;
  }
}

@media(min-width: 767px) and (max-width: 924px){
    .serv{
        padding:50px !important;
    }
    .grad1{
        width:350px !important;
    } }

@media(min-width: 500px) and (max-width: 767px){
    .about4, .about3{
      padding:30px !important;
    }
    .serv{
        padding:30px !important;
        height: 450px !important;
    }
    .grad2{
       width: 300px !important;
       
    }
    .css-typing3{
      font-size: 35px !important;
      margin-top:50px!important;
    } 
    .wor{
      margin-top:-15px !important;
    }
    .webu{
      font-size:28px !important;
      margin-top: 70px !important;
    }
    .crad{
      width: 150px !important;
      top: 50px !important;
    }
    .paraf{
      font-size:16px !important;
    }
    .smdiv{
      font-size: 14px !important;
    }
    .img1{
     width: 90% !important;
     bottom: 0px !important;
   
    }
    .img1sh{
     width:300px !important;
    }
    .lineo{
     display: none !important;
    }
    .img2sh{
      display: none !important;
    }
    .img2{
      width: 100% !important;
      margin-left: 25px !important;
      position: relative !important;
    }
    .webi{
      font-size:28px !important;
      margin-top: -130px !important;
    }
    .about4{
      padding-top: 30px !important;
    }
  }
  @media(min-width: 280px) and (max-width: 500px){
    .about3, .about4{
      padding:20px !important;
    }
    .webu{
      font-size:28px !important;
      margin-top: 70px !important;
    }
    .crad{
      width: 120px !important;
      top: 50px !important;
    }
    .paraf{
      font-size:16px !important;
    }
    .smdiv{
      font-size: 14px !important;
    }
    .img1{ 
      position: unset !important;
      width: 100% !important;
    }
    .img1sh{
      display: none !important;
      margin-top: -70px !important;
    }
    .lineo{
      display: none !important;
    }
    .img2sh{
      display: none !important;
    }
    .img2{
      width: 100% !important;
      margin-left: 25px !important;
      position: relative !important;
      top: 0px !important;
    }
    .webi{
      font-size:28px !important;
      margin-top: -50px !important;
    }
    .about4{
      padding-top: 20px !important;
    }
  }

 @media(min-width: 385px) and (max-width: 500px){
    .serv{
        padding:20px !important;
        height: 350px !important;
    }
    .grad2{
        width: 250px !important;
        margin-top:-50px !important;
    }
    .wor{
        margin-top: -20px!important;
    }
    .css-typing3{
        font-size: 26px !important;
        margin-top:50px!important;
    } }

@media(min-width: 280px) and (max-width: 385px){
    .serv{
        padding:20px !important;
        height: 300px !important;
    }
    .grad2{
        width: 200px !important;
        margin-top:-60px !important;
    }
    .menucls1{
        width: 20px !important;
    }
    .log{
        width: 25px !important;
    }
    .css-typing3{
        font-size: 20px !important;
        margin-top:50px!important;
        
    } } 

    @media(min-width: 1225px) and (max-width: 1377px){
      .embu{
        font-size: 42px !important;
      }
      .parag{
        font-size: 20px !important;
      }
    }




.css-typing3 div {
    border-right: .10em solid rgb(16, 17, 16);
    white-space: nowrap;
    overflow: hidden;
  }
  .css-typing3 div:nth-child(1) {
    width: 5.8em;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing3 div:nth-child(2) {
    width: 12.5em;
    opacity: 0;
    -webkit-animation: type2 2s steps(70, end);
    animation: type2 2s steps(70, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid #d6831e;
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid rgb(126, 43, 7);
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid rgb(187, 76, 12);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid rgb(2, 5, 3);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
 
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
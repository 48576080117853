.home{
  padding: 65px;
}
.out{
text-align: center;
font-size: 40px;
margin-top: 40px;
font-weight: 500px;
}
.eve{
  text-align: center;
  font-size: 25px;
  margin-top: 30px;
  font-weight: 500px;
}
.lefrigh{
display: flex;
justify-content: center;
margin-top: 60px;
margin-bottom: 60px;
}
.para{
color: #ffffff;
margin-left: 30px;
font-size: 22px;
}
.css-typing11, .css-typing1{
display: none;
}
.menupage{
  height: 755px;
  background-color: black;
  padding: 65px;
  position: absolute;
  top: 0px;
   width: 100%;
}
.admcl{
  padding: 25px;
  height: 133px;
  width: 200px;
  background-color: black;
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  top: 450px;
  left: 300px;
}
.proim{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2); 
  margin-top: 15px;
  border-radius: 10px;
 
}
.prn{
    background-color: black;
    height: 120px;
    width: 120px;
    padding: 10px;
    z-index: 20;
    box-shadow: 0 2px 1px 0 #ccc;
    -webkit-box-shaddow: 0 0 1px 0 #ccc;
    -moz-box-shadow:0 0 1px 0 #ccc;
    border-radius: 60px;
    max-width: 350px;
}
@media(min-width: 767px) and (max-width: 945px){
.css-typing{
  display: none !important;
}
.css-typing11{
  font-size: 35px !important;
  margin-top: 100px !important;
  display: block !important
}
.grad{
  width: 250px !important;
}
.css-typing1{
  display: none !important;
}
.css-typing111{
  font-size: 34px !important;
 
  display: block !important
}
.para{
  font-size: 18px !important;
  }
 }
 

@media(min-width: 280px) and (max-width: 767px){
.out{
    font-size: 25px !important; 
}
.clien{
  width: 80% !important;
}
.lef, .righ{
  width: 100px !important;
}
.css-typing{
  display: none !important;
}
.css-typing11{
  font-size: 30px !important;
  margin-top: 100px !important;
  display: block !important
}
.grad{
  width: 280px !important;
}  
.gradhead{
  display:flex;
  justify-content: center;
}
.home{
  padding:20px !important;
}
.elipvec{
  margin-top:10px!important;
  margin-bottom:0px !important;
}
.parap{
  margin-top: 60px !important;
margin-bottom: 60px !important;
}
.css-typing1{
  display: none !important;
}
.css-typing111{
  font-size: 28px !important;
  display: block !important
}
.para{
  font-size: 16px !important;
  }
  .black{
    padding: 20px !important;
  }
}
.gradient2{
width: 200px;
position: absolute;
left: 0px;
top: 200px;
}
.vect{
  width: 8px;
  position: absolute;
  top: 9px;
}
.vect:hover{
  transform: scale(1.50);
  box-shadow:  black;
}
.black{
  background: black;
  padding:65px;
  position: relative;
 
}
.menucls:hover{
  transform: scale(1.7);
 
}
.line-1{
  position: relative;
  top: 50%;  
  width: 24em;
  margin: 0 auto;
  border-right: 2px solid rgba(255,255,255,.75);

  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);    
}

.list-inline {
  display: inline-block;
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.list-inline>li> button {
  background-color: #000000;
  color: #fff;
  font-weight: bold;
  border-color: #000000;
  height: 41px;
  border-radius: 25px;
}

.list-inline>li> button:hover {
  background-color: #fff;
  color: #000000;
  font-weight: bold;
  border-color: #000000;
  height: 41px;
  border-radius: 25px;
}



























/* .home{
    padding: 65px;
}
.out{
  text-align: center;
  font-size: 40px;
  margin-top: 40px;
  font-weight: 500px;
}
.lefrigh{
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.para{
  color: #ffffff;
  margin-left: 30px;
  font-size: 22px;
}
.css-typing11{
  display: none;
}

@media(min-width: 767px) and (max-width: 945px){

  .css-typing{
    display: none !important;
  }
  .css-typing11{
    font-size: 35px !important;
    margin-top: 100px !important;
    display: block !important
  }
  .grad{
    width: 250px !important;
  }
   }
   .css-typing1{
    display: none !important;
  }
  .css-typing111{
    font-size: 34px !important;
    margin-top: 100px !important;
    display: block !important
  }
.para{
  font-size: 18px !important;
}
@media(min-width: 280px) and (max-width: 767px){
  .out{
      font-size: 25px !important; 
  }
  .clien{
    width: 80% !important;
  }
  .lef, .righ{
    width: 100px !important;
  }
  .css-typing{
    display: none !important;
  }
  .css-typing11{
    font-size: 30px !important;
    margin-top: 100px !important;
    display: block !important
  }
  .grad{
    width: 280px !important;
  }  
  .gradhead{
    display:flex;
    justify-content: center;
  }
  .home{
    padding:20px !important;
  }
  .elipvec{
    margin-top:30px!important;
    margin-bottom:0px !important;
  }
} */
/* 
@media(min-width: 768px) and (max-width: 945px){
  .para{
      font-size: 18px !important; 
      
  }
  .css-typing1{
    font-size: 40px !important;
    
} 

}  */

/* @media(min-width: 280px) and (max-width: 767px){
  .css-typing1{
      font-size: 30px !important;
      margin-top: 60px !important;       
  } 
    .com{
        margin-left: 20px !important;
    }  
    .para{
      margin-top: -500px !important;
      font-size: 14px !important;
      margin-bottom: 50px !important;
}
.stay{
  font-size: 30px !important;
}
.why1{
  font-size: 20px !important;
}
.logo222{
  display: flex !important;
  justify-content: center !important;
  margin-right: 0px !important;
}
.logo22{
  width: 80 !important;
}
} */

  /* @media(min-width: 767px) and (max-width: 1100px){

.logo22{
  margin-top: 40px !important;
}
 }

 @media(min-width: 280px) and (max-width: 767px){
  .elements{
    float: left !important;
  }
  .black{
    padding:10px !important
  }
  .home, .foot{
    padding: 10px !important;
  }
  .elipvec{
    margin-top: 20px !important;
  }
 } */

 

 


 

/* .gradient2{
  width: 200px;
  position: absolute;
  left: 0px;
  top: 200px;
}
.vect{
    width: 8px;
    position: absolute;
    top: 9px;
}
.vect:hover{
    transform: scale(1.50);
    box-shadow:  black;
}
.black{
    background: black;
    padding:65px;
    position: relative;
   
}
.menucls:hover{
    transform: scale(1.7);
   
}
.line-1{
    position: relative;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
  
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
} */

/* Animation */
/* .anim-typewriter{
  animation: typewriter 4s steps(44) 1s 1 normal both,
             blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
  from{width: 0;}
  to{width: 24em;}
}
@keyframes blinkTextCursor{
  from{border-right-color: rgba(255,255,255,.75);}
  to{border-right-color: transparent;}
} */


.css-typing div {
    border-right: .10em solid rgb(16, 17, 16);
    white-space: nowrap;
    overflow: hidden;
  }
  .css-typing div:nth-child(1) {
    width: 11.3em;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .css-typing div:nth-child(2) {
    width: 14.5em;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid rgb(15, 204, 18);
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid rgb(30, 83, 29);
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid rgb(21, 158, 23);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid rgb(2, 5, 3);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
 
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
  
  

  
  .css-typing1 div {
    border-right: .10em solid rgb(16, 17, 16);
    white-space: nowrap;
    overflow: hidden;
  }
  .css-typing1 div:nth-child(1) {
    width: 4.3em;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing1 div:nth-child(2) {
    width: 8.5em;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid rgb(15, 204, 18);
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid rgb(30, 83, 29);
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid rgb(21, 158, 23);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid rgb(2, 5, 3);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
 
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
   

/* @media(min-width: 767px) and (max-width: 945px){
    .elements{
        margin-right: 100px !important;
    } } */
















/* .gif{
    width: 100%;
}
.pagif{
  margin-top: 20px;  
}
.imgg1{
    width: 100%;
    height: 300px;
}
.section{
    background-color: #6d39c4;
    padding: 30px;
    background-image: url(../../Assets/Images/Group.png);
    background-size: cover;
}
.what{
    font-size: 50px;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
}
.group{
    width: 100%;
}
.d{
   max-width: 100%;
   margin-top: 60px; 
   padding: 20px;
}
.asd{
    width: 100%;
    margin-top: 20px;
}
 #gradient
{ 
    font-size:30px;
    background: linear-gradient(130deg, #ffffff, #ffffff, #e1a3ff);
    background-size: 200% 200%;
   
    -webkit-animation: Animation 5s ease infinite;
    -moz-animation: Animation 5s ease infinite;
    animation: Animation 5s ease infinite;
}

@-webkit-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes Animation { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
} */

.more{
  margin: top 40px;
}
.inmore1{
  background-color: rgb(0, 0, 0);
}
.alldiv{
  padding: 50px;
}
.web{
  font-size: 30px;
  font-weight: bold;
}

.pro{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2); 
  margin-top: 15px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content:space-between;
}
.web1{
  font-size: 30px;
  color:#ffffff;
  font-weight: bold;
}
.pro1{
  background-color: white;
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2); 
  margin-top: 15px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content:space-between;
}

.list-inline>li> button {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  border-color: #000;
  height: 30px;
  border-radius: 2px;
  margin-top: 5px;
}

.list-inline>li> button:hover {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  border-color: #000;
  height: 30px;
  border-radius: 2px;
  margin-top: 5px;
}


.head{
  padding: 10px;
  padding-top: 20px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
}
.cart-div{
  position: absolute;
  width: 420px;
  right: 0;
  margin-top: 13px;
  min-height: 380px 
}
.mobileapp{
display: flex;
margin-left: 30px;
}
.gtp{
  margin-left: 5px;
}
.btns{
  display: flex;
  justify-content: space-between;
}
.btns1{
  border: 1px solid #7c003f;
  padding: 5px;
  width: 45%;
  border-radius: 20px;
  font-size: 12px;
  height: 28px;
  display: flex;
  justify-content: space-between;
}

.metlog{
  width: 60px;
  height: 45px;
  margin-top: -10px;
}
.metfd{
  display: flex;

}
.metfdname{
  font-size: 30px;
  margin-left: -5px;
  margin-top: -10px;
  font-weight: bold;
  color: #7c003f;
}
.signitem{
  display: flex;
  justify-content: space-between;
}
.signitem1{
  border: 1px solid black;
  padding: 5px;
  width: 45%;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  height: 28px;
  text-align: center;
}
@media(max-width:767px){
  .metfd{
      margin-top: 20px;
  }
}

.img-hei{
  width: 100% !important;
  height: 300px;
  border-radius: 20px;  

}
.meta{
  width: 100%;
  height: 200px;
  border-radius: 20px;
}

.divv{
  color: #8b8b8b;
}
.serv{
padding:65px;
background-color:black;
height:  755px;
}
.wesl{
position: absolute;
top: -140px;
position: absolute;

}
.about3{
padding: 65px;
}
.lineo{
position:absolute;
top: -65px;
left: -65px;
}
.crad {
position: absolute;
width: 200px;
right: 0px;
top: 210px;
margin-right: 10px;
}
.img1{
position: absolute;
bottom: 200px;
}
.about4{
padding:65px;
background-color:black;
padding-top: 10px;
}
.img2{
position: absolute;
top: -115px;
}
.img2sh{
  width: 600px;
  position: absolute;
  left: 0px;
  top: 115px;
}

.picdiv{
padding:65px;
height: 700px;
background-color: #f3f3f3;
}
.picdiv1{
padding:65px;
height: 700px;
background-color: #f3f3f3;
}
/* .recg{
position: relative;
} */
.num{
font-size: 45px;
position: absolute;
font-weight: bold;
left: -90px;
}
.appnm{
font-size: 45px;
position: absolute;
left: -67px;
font-weight:550;
top: 80px;
}
.depnm{
font-size: 48px;
position: absolute;
left: -70px;
font-weight:550;
top: 135px;
}
.praf{
position: absolute;
top: 385px;
left: -30px;
}
.wwdar{
display: flex;
position: absolute;
left: -90px;
bottom:290px
}
.num2{
font-size: 45px;
position: absolute;
font-weight: bold;
right: -65px;
z-index: 1;
}
.sing{
font-size: 45px;
position: absolute;
font-weight:550;
top: 80px;
right: -40px;
z-index: 1;
}
.plat{
font-size: 45px;
position: absolute;
font-weight:550;
top: 135px;
z-index: 1;
right: -40px;
}
.paraexp{
position: absolute;
top: 385px;
text-align: justify;
left: 15px;
}
.wwdal{
display: flex;
position: absolute;
right: -70px;
bottom:290px
}
.devim{
  margin: top 100px;
}
.smdiv1{
position: absolute;
top: 275px;
left: -40px;
}
@media(min-width: 767px) and (max-width: 1350px){
.num{
  font-size: 40px!important;
  left: -35px;
}
.appnm{
  font-size: 40px!important;
  left: -13px!important;
}
.depnm{
  font-size: 40px!important;
  left: -14px!important;
}
.praf{
 font-size: 15px !important;
  top: 380px !important;
  left: 20px !important;
}
.smdiv1{
  left: 4px!important;
}
.wwdar{
display: -webkit-inline-box !important;
position: absolute;
left: -90px!important;
bottom:295px!important;
}
.clri{
  margin-left: 100px !important;
  width: 150px !important;
}
.wtwdo{
   margin-left: 50px !important;  
}
.wwdal{
  top: 550px !important;
  font-weight: bold !important;
}
.clile{
  width: 100px !important;
 margin-right: 250px !important;
 height: 50px !important;
}
.wtwedo{
  font-size: 18px !important;
}
.num2{
  font-size: 40px!important;
 
}
.sing{
  font-size: 38px!important;

}
.plat{
  font-size: 38px!important;

}
.paraexp{
  top: 330px !important;
}
}

@media(min-width: 500px) and (max-width: 767px){
.picdiv{
padding: 30px !important;
padding-bottom: unset !important;
}
.colcol, .colcol2{
position: unset !important;
}
.recg, .recb{
display: none !important;
}
.num, .num2{
font-size: 35px !important;
position: unset !important;
font-weight: bold;
}
.appnm, .sing{
font-size: 35px !important;
position: unset !important;
font-weight:550;
}
.depnm, .plat{
font-size: 35px !important;
position: unset !important;
font-weight:550;
}
.praf, .paraexp{
position: unset !important;
margin-top: 15px !important;
font-size: 15px !important;
text-align: unset !important;
}
.smdiv1{
position: unset !important;
margin-top: 10px !important;
}
.wwdar, .wwdal{
display: flex;
position: unset !important;
justify-content: space-between;
margin-top: 15px !important;
font-weight: bold !important;
}
.wtwdo, .wtwedo{
font-size: 18px !important;
}
.colimg{
margin-top: -70px !important;
display: flex;
justify-content: center;
}
.devimg, .devimg1{
width: 80% !important;
margin-top: 30px !important;
}
.devimg2{
width: 80% !important;
}
.clile{
margin-right: unset !important;
}
}
@media(min-width: 280px) and (max-width: 500px){
.picdiv, .picdiv1{
  padding: 20px !important;
  height: 600px !important;
}
.colcol, .colcol2{
  position: unset !important;
}
.recg, .recb{
  display: none !important;
}
.num, .num2{
  font-size: 25px !important;
  position: unset !important;
  font-weight: bold;
}
.appnm, .sing{
  font-size: 25px !important;
  position: unset !important;
  font-weight:550;
}
.depnm, .plat{
  font-size: 25px !important;
  position: unset !important;
  font-weight:550;
}
.praf, .paraexp{
  position: unset !important;
  margin-top: 15px !important;
  font-size: 12px !important;
}
.smdiv1{
  position: unset !important;
  margin-top: 10px !important;
}
.wwdar, .wwdal{
position: unset !important;
margin-top: 15px !important;
font-weight: bold !important;
}
.wtwdo, .wtwedo{
  font-size: 16px !important;
}
.cliri, .clile{
  display: none!important;
}
.colimg{
  margin-top: -30px !important;
  display: flex;
  justify-content: center;
}
.devimg{
  width: 100% !important;
}
.devimg1, .devimg2{
  width: 80% !important;
}
}

@media(min-width: 962px) and (max-width: 1322px){
.webu{
  font-size:30px !important;
}
.crad{
  display: none !important;
}
.paraf{
  font-size:16px !important;
}
.img1sh{
 width:400px !important;
}
.col{
  margin-top:50px  !important
}
.img2sh{
  width: 450px !important;
  top: 120px !important;
}
.webi{
  font-size:30px !important;
  margin-top:150px !important
}
}
@media(min-width: 767px) and (max-width: 962px){
.webu .webi{
  font-size:28px !important;
  margin-top: 100px !important;
}
.crad{
  display: none !important;
}
.paraf{
  font-size:16px !important;
}
.img1sh{
  display: none !important;
}
.lineo{
  width: 350px !important;
}
.webi{
  font-size:28px !important;
  margin-top: 20px !important;
}
.img2{  
  margin-top: 100px !important;
}
.img2sh{
  width: 350px !important;
  top: 150px !important;
}
}

@media(min-width: 767px) and (max-width: 924px){
  .serv{
      padding:50px !important;
  }
  .grad1{
      width:350px !important;
  } }

@media(min-width: 500px) and (max-width: 767px){
  .about4, .about3{
    padding:30px !important;
  }
  .serv{
      padding:30px !important;
      height: 450px !important;
  }
  .grad2{
     width: 300px !important;
     
  }
  .css-typing3{
    font-size: 35px !important;
    margin-top:50px!important;
  } 
  .wor{
    margin-top:-15px !important;
  }
  .webu{
    font-size:28px !important;
    margin-top: 70px !important;
  }
  .crad{
    width: 150px !important;
    top: 50px !important;
  }
  .paraf{
    font-size:16px !important;
  }
  .smdiv{
    font-size: 14px !important;
  }
  .img1{
   width: 90% !important;
   bottom: 0px !important;
 
  }
  .img1sh{
   width:300px !important;
  }
  .lineo{
   display: none !important;
  }
  .img2sh{
    display: none !important;
  }
  .img2{
    width: 100% !important;
    margin-left: 25px !important;
    position: relative !important;
  }
  .webi{
    font-size:28px !important;
    margin-top: -130px !important;
  }
  .about4{
    padding-top: 30px !important;
  }
}
@media(min-width: 280px) and (max-width: 500px){
  .about3, .about4{
    padding:20px !important;
  }
  .webu{
    font-size:28px !important;
    margin-top: 70px !important;
  }
  .crad{
    width: 120px !important;
    top: 50px !important;
  }
  .paraf{
    font-size:16px !important;
  }
  .smdiv{
    font-size: 14px !important;
  }
  .img1{ 
    position: unset !important;
    width: 100% !important;
  }
  .img1sh{
    display: none !important;
    margin-top: -70px !important;
  }
  .lineo{
    display: none !important;
  }
  .img2sh{
    display: none !important;
  }
  .img2{
    width: 100% !important;
    margin-left: 25px !important;
    position: relative !important;
  }
  .webi{
    font-size:28px !important;
    margin-top: -50px !important;
  }
  .about4{
    padding-top: 20px !important;
  }
}

@media(min-width: 385px) and (max-width: 500px){
  .serv{
      padding:20px !important;
      height: 350px !important;
  }
  .grad2{
      width: 250px !important;
      margin-top:-50px !important;
  }
  .wor{
      margin-top: -20px!important;
  }
  .css-typing3{
      font-size: 26px !important;
      margin-top:50px!important;
  } }

@media(min-width: 280px) and (max-width: 385px){
  .serv{
      padding:20px !important;
      height: 300px !important;
  }
  .grad2{
      width: 200px !important;
      margin-top:-60px !important;
  }
  .menucls1{
      width: 20px !important;
  }
  .log{
      width: 25px !important;
  }
  .css-typing3{
      font-size: 20px !important;
      margin-top:50px!important;
      
  } } 

  @media(min-width: 1225px) and (max-width: 1377px){
    .embu{
      font-size: 42px !important;
    }
    .parag{
      font-size: 20px !important;
    }
  }




.css-typing3 div {
  border-right: .10em solid rgb(16, 17, 16);
  white-space: nowrap;
  overflow: hidden;
}
.css-typing3 div:nth-child(1) {
  width: 5.8em;
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing3 div:nth-child(2) {
  width: 12.5em;
  opacity: 0;
  -webkit-animation: type2 2s steps(70, end);
  animation: type2 2s steps(70, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}


@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: .10em solid #d6831e;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: .10em solid rgb(126, 43, 7);
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .10em solid rgb(187, 76, 12);
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .10em solid rgb(2, 5, 3);
  }
  100% {
    opacity: 1;
    border: none;
  }
}


@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}

.checkout{
  margin-top: 30px;
}
.out{
  font-size: 20px;
  font-weight: 700;
  color: #7c003f;
}
.vacct{
  display: flex;
  margin-top: 20px;
}
.acc{
  font-size: 24px;
  font-weight: 600;
}
.tick{
  background-color: green;
  border-radius: 40px;
  height: 20px;
  width: 20px;
  margin-top: 11px;
  margin-left: 6px;
}
.division{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px;
 
}
.btnm{
  font-size: 10px;
  margin-bottom: unset;
}
.vadel{
  display: flex;
  margin-top: 30px;
}
.smdiv{
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
}
.butt{
  border: none !important;
  box-shadow: none !important;
}
.hommin{
  font-size: 14px;
  font-weight: 700;
}
.addpara{
  font-size: 10px;
  margin-top: 10px;
  font-weight: 700;
}
.btddiv{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.chdiv{
  margin-top: 20px;
}
.chan{
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  margin-top: 20px;
  color: #7c003f;
  font-weight: bold;
}
.homin{
  font-size: 16px;
  font-weight: 700;
}
 
.kptp{
  font-size: 13px;
  font-weight: 700;
  margin-top: 15px;
}
.codv{
  display: flex;
  justify-content: center;
}
.coddiv{
border: 1px solid black;
width: 100px;
height: 60px;
margin-top: 20px;
box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);

}
.coddiv:hover{
  transform: scale(1.02);
  box-shadow:  black;
}
.cod{
  padding: 15px;
  padding-left: 30px;
}
.paydiv{
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}
.smdivision{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  margin-top: 20px;
  border-radius: 20px;
  padding: 10px;
}
.imgminplus{
  display: flex;
}
.minplus{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  width: 50px; 
  height: 25px; 
  margin-left: 10px;
  margin-top: 7px;
}
.mipl{
  display: flex;
  justify-content: space-between;
  padding-left: 6px;
  padding-right: 5px;
}
.covid{
  background-color: #f5f5f5;
  padding: 10px;
}
.srchcpn{
  margin-top: 15px;
  border: 1px dashed rgb(97, 94, 94);
  border-radius: 5px;
  
}
.coupon{
  height: 50px;
  border: none;
}
.apply{
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
.checkfoot{
 background: #d6d4d4;
 text-align: center;
 padding: 30px;
 margin-top: 50px;
}
.implus{
  box-shadow: 0px 1px 3px 1px rgb(116 116 116 / 20%);
  background-color: #f5f5f5;
  margin-top: 20px;
  border-radius: 20px;
  padding: 8px;
  width: 172px;
  margin-bottom: 20px;
}
@media(max-width:767px){
  .pop{
      margin-top: 20px !important;
  }
}
.applybtn, .btd, .pay{
  border: none !important;
  box-shadow: none !important;
}
.box-inside1{    
    z-index: 20;
    box-shadow: 0 2px 1px 0 #ccc;
    -webkit-box-shaddow: 0 0 1px 0 #ccc;
    -moz-box-shadow:0 0 1px 0 #ccc;
    border-radius: 10px;
    margin-top: 30px;
    max-width: 350px;
    /* box-shadow: 0px 8px 5px #ccc; */
}
.lbx{
    background-color: white;
    height: 550px;
    position: absolute;
    left: 37%;
    width: 400px;
    padding: 40px;
    box-shadow: 0 4px 10px 0 #ccc;
    margin:20px auto 30px;
}

.logmndiv1{
    display: none !important;
    }
.col1div1{
    padding: 65px;    
}
@media(min-width: 767px) and (max-width: 1200px){
    .lbx {
        left: 30% !important;
    }
}
@media(min-width: 280px) and (max-width: 767px){
    col-2{
        display: none;
    }
    .lbx {
        left: unset !important;
        margin-top: 60px !important;
        height: 500px !important;
    }  
    .logmndiv{
        display: none !important;
        }
    .logmndiv1{
        display: flex !important;
    } 
        
}

@media(min-width: 280px) and (max-width: 500px){
    .lbx{
       width: 300px !important;
    }
}

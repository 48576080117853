.logo{
    width: 30px;
}
.head{
    padding: 10px;
    padding-top: 20px;
    /* position: fixed; */
    width: 100%;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
}
.hascbpl{
    font-weight: 550;
}
.blog{
    padding: 65px;
    height: 750px;
}
.bname{
    position: absolute;
    right: 470px;
    top: 100px;
    font-size: 50px;
    font-weight: 500;
}
.bimage{
    height: 100%;
    width: 100%;
   background-image: url(../../Assets/Images/indoor\ .jpg)
}
.bimage2{
    height: 100%;
    width: 100%;
    background-image: url(../../Assets/Images/int1.jpg)
}
.bname1{
    position: absolute;
    left: 180px;
    top: 324px;
    font-size: 50px;
    font-weight: 500;
    color: #ffffff;
}
.bname2{
    position: absolute;
    top: 340px;
}
.bimage3{
    height: 100%;
    width: 100%;
   background-image: url(../../Assets/Images/blue.jpg)
}
.bname3{
    position: absolute;
    top: 170px;
    text-align: center;
    font-size: 80px;
    font-weight: bold;
    color: #ffffff;
}
.bshape{
    border: 1px solid yellow;
    background-color:yellow;
    padding: 5px;
}
.bwhite{
    position: absolute;
    top: 624px;
    width: 700px;
    right: 100px;
    background-color: white;
}
.bsmimgn{
    height:150px;
    width:290px;
    margin-left: -24px;
}
.bglow{
    padding: 65px;
    height: 750px;
}
.imim0{
    height:250px;
    width:290px;
}
.imim1{
    height:355px;
    width:266px;
    position: absolute;
    left: 268px;
}
.imim2{
    height:305px;
    width:250px;
    position: absolute;
    left: 512px;
}
.imim3{
    height:195px;
    width:300px;
    position: absolute;
    left: 740px;
}
.imim4{
    height:278px;
    width:238px;
    position: absolute;
    right: 263px
}
.imim5{
    height:240px;
    width:285px;
    position: absolute;
    right: 0px;
}
.imim01{
    height:304px;
    width:290px;
    position: absolute;
    top: 2px;
}
.imim11{
    height:395px;
    width:266px;
    position: absolute;
    left: 268px;
    top: 107px;
}
.imim21{
    height:272px;
    width:250px;
    position: absolute;
    left: 512px;
    top: 57px;
}
.imim31{
    height:266px;
    width:300px;
    position: absolute;
    left: 740px;
    top: -53px;
}
.imim41{
    height:278px;
    width:238px;
    position: absolute;
    right: 263px;
    top: 30px;
}
.imim51{
    height:270px;
    width:285px;
    position: absolute;
    right: 0px;
    top: -8px;
}
.imim02{
    height:194px;
    width:290px;
    position: absolute;
    top: 308px;
}
.imim22{
    height:171px;
    width:250px;
    position: absolute;
    left: 512px;
    top: 331px;
}
.imim32{
    height:287px;
    width:300px;
    position: absolute;
    left: 740px;
    top: 215px;
}
.imim42{
    height:192px;
    width:238px;
    position: absolute;
    right: 263px;
    top: 310px;
}
.imim52{
    height:238px;
    width:285px;
    position: absolute;
    right: 0px;
    top: 264px;
}
.im0{
    position: relative;
}
.im1{
    position: relative;
}
.im2{
    position: relative;
}
@media(min-width: 835px) and (max-width: 1000px){
.bwbfb{
    color: white !important;
}
.bname{
    color: white !important;
}
}
@media(min-width: 681px) and (max-width: 834px){
    .bwbfb{
        color: white !important;
        font-size: 25px !important;
    }
    .bname{
        color: white !important;
        font-size: 40px !important;
        right: 283px !important;
        top: 120px !important;
    }
}
@media(min-width: 551px) and (max-width: 680px){
    .blog{
        padding: 25px !important;
    }
    .bwbfb{
        color: white !important;
        font-size: 25px !important;
    }
    .bname{
        color: white !important;
        font-size: 40px !important;
        right: 180px !important;
        top: 207px !important;
    }
}
@media(min-width: 300px) and (max-width: 550px){
    .blog{
        padding: 20px !important;
    }
    .bwbfb{
        color: white !important;
        font-size: 20px !important;
    }
    .bname{
        color: white !important;
        font-size: 35px !important;
        right: 0px !important;
        top: 120px !important;
    }
}
@media(min-width: 768px) and (max-width: 1200px){
    .bclpd{
        font-size: 25px !important
    }
    .bname1{
        font-size: 40px !important;
        left: 100px !important;
        top: 320px !important;
    }
    .bimg2{
        width: 260px !important;
        margin-top: 180px !important;
        margin-left: 50px !important;
    }
}
@media(min-width: 601px) and (max-width: 767px){
    .bclpd{
        font-size: 25px !important
    }
    .bname1{
        font-size: 40px !important;
        left: 180px !important;
        top: 80px !important;
    }
    .bimg2{
        width: 400px !important;
       
    }
    .bname2{
        top: 374px !important;
        left: 115px !important;
    }
   
}
@media(min-width: 300px) and (max-width: 600px){
    .bclpd{
        font-size: 25px !important
    }
    .bname1{
        font-size: 40px !important;
        left: 30px !important;
        top: 100px !important;
    }
    .bimg2{
        width: 250px !important;
        left: 30px !important;
    }
    .bname2{
        top: 435px !important;
        left: 30px !important;
    }
   
}

@media(min-width: 501px) and (max-width: 768px){
    .bname3{
        font-size: 50px !important;
        top: 100px !important;
    }
    .bwhite{
        top: 379px !important;
        width: 290px !important;
        right: 200px !important;
    }
    .bpf{
        font-size:30px !important;
    }
}
@media(min-width: 300px) and (max-width: 500px){
    .bname3{
        font-size: 40px !important;
        top: 100px !important;
    }
    .bwhite{
        top: 381px !important;
        width: 290px !important;
        left: 5px !important;
       
    }
    .bpf{
        font-size:20px !important;
    }
}

/* @media(min-width: 1450px) and (max-width: 1500px){
    .imim0{
        width:270px;
    }
    .imim1{
        width:266px;
    }
    .imim2{
        width:230px;
    }
    .imim3{
        width:280px;
    }
    .imim4{
        width:0px !important;
    }
    .imim5{
        width:265px;
    }
    .imim01{
        width:270px;
    }
    .imim11{
        width:266px;
    }
    .imim21{
        width:230px;
    }
    .imim31{
        width:280px;
    }
    .imim41{
        width:0px !important;
    }
    .imim51{
        width:265px;
    }
    .imim02{
        width:270px;
    }
    .imim22{
        width:230px;
    }
    .imim32{
        width:280px;
    }
    .imim42{
        width:0px !important;
    }
    .imim52{
        width:265px;
    }
} */
.about{
    padding:65px;
    background-color:black;
    height:  755px;
}
.menucls1:hover{
    transform: scale(1.7);
   
}
.about1, .about2, .about3{
    padding:65px;
}
.hrdiv{
  background-color:black;
}
.parag{
  font-weight: 300px;
 
}
.lineimg{
  position:absolute;
  top: -65px;
  right:-65px;
}

@media(min-width: 767px) and (max-width: 924px){
    .about{
        padding:50px !important;
        height: 650px !important
    }
    .grad1{
        width:350px !important;
    } }
@media(min-width: 500px) and (max-width: 767px){
    .about{
        padding:10px !important;
        height: 550px !important
    }
    .grad1{
       width: 300px !important;
        margin-top:-60px !important;
    }
    .css-typing2{
      font-size: 35px !important;
      margin-top:100px!important;
    } }
 @media(min-width: 385px) and (max-width: 500px){
    .about{
        padding:10px !important;
        height: 500px !important
    }
    .grad1{
        width: 250px !important;
        margin-top:-60px !important;
    }
    .work{
        margin-top: -10px!important;
    }
    .css-typing2{
        font-size: 30px !important;
        margin-top:100px!important;
    } }
@media(min-width: 280px) and (max-width: 385px){
    .about{
        padding:10px !important;
        height: 400px !important
    }
    .grad1{
        width: 200px !important;
        margin-top:-60px !important;
    }
    .work{
        margin-top: -10px!important;
    }
    .menucls1{
        width: 20px !important;
    }
    .log{
        width: 25px !important;
    }
    .css-typing2{
        font-size: 27px !important;
        margin-top:50px!important;
    } } 

    @media(min-width: 1225px) and (max-width: 1377px){
      .embu{
        font-size: 42px !important;
      }
      .parag{
        font-size: 20px !important;
      }
    }
    @media(min-width: 1048px) and (max-width: 1225px){
      .embu{
        font-size: 35px !important;
      }
      .parag{
        font-size: 20px !important;
      }
      .lineimage{
        width: 700px !important;
      }
    }
    @media(min-width: 870px) and (max-width: 1048px){
      .embu{
        font-size: 28px !important;
      }
      .parag{
        font-size: 20px !important;
      }
      .lineimage{
        width: 500px !important;
      }
    }
    @media(min-width: 653px) and (max-width: 870px){
      .about1{
        padding:50px !important;
      }
      .parag{
        font-size: 18px !important;
        margin-bottom: 50px !important;
      }
      .embu{
        margin-top: 50px !important;
        font-size: 25px !important;
        font-weight: bold !important;
      }
      
      .lineimage{
        width: 0px !important;
      }
      
    }
   
    @media(min-width: 581px) and (max-width: 653px){
      .about1{
        padding:35px !important;
      }
      .parag{
        font-size: 18px !important;
        margin-bottom: 40px !important;
      }
      .embu{
        margin-top: 40px !important;
        font-size: 23px !important;
        font-weight: bold !important;
      }
      
      .lineimage{
        width: 0px !important;
      }
      .prag{
        font-size: 14px !important;
       }
      
    }
    @media(min-width: 280px) and (max-width: 581px){
      .about1, .about2{
        padding:10px !important;
      }
      .parag{
        font-size: 15px !important;
        margin-bottom: 20px !important;
      }
      .embu{
        margin-top: 20px !important;
        font-size: 20px !important;
        font-weight: bold !important;
      }
      
      .lineimage{
        width: 0px !important;
      }
      .abt{
        font-size: 20px !important;
      }
      .prag{
       font-size: 14px !important;
      }
    }

  .css-typing2 div {
    border-right: .10em solid rgb(16, 17, 16);
    white-space: nowrap;
    overflow: hidden;
  }
  .css-typing2 div:nth-child(1) {
    width: 9.2em;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing2 div:nth-child(2) {
    width: 10.5em;
    opacity: 0;
    -webkit-animation: type2 2s steps(70, end);
    animation: type2 2s steps(70, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid rgb(204, 25, 25);
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .10em solid rgb(102, 6, 6);
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid rgb(187, 12, 12);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .10em solid rgb(2, 5, 3);
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
 
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
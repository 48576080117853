














@media(min-width: 280px) and (max-width: 768px){
    
  .stay{
    font-size: 30px !important;
  }
  .why1{
    font-size: 20px !important;
  }
  .logo222{
    display: flex !important;
    justify-content: center !important;
    margin-right: 0px !important;
  }
  .logo22{
    width: 80 !important;
  }
  } 
   @media(min-width: 767px) and (max-width: 1100px){
  
  .logo22{
    margin-top: 40px !important;
  }
   }
   @media(min-width: 280px) and (max-width: 767px){
    .elements{
      float: left !important;
    }
    
    .foot{
      padding: 10px !important;
    }
    
   }
   @media(min-width: 280px) and (max-width: 386px){
    
    .boot{
      margin-left: 20px !important;
    }
  }